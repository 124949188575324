import { createApp } from "vue"
import { createWebHashHistory, createRouter } from "vue-router"
import VueGtag from "vue-gtag"

// views
import Landing from './views/Landing.vue'

// styles
import "@fortawesome/fontawesome-free/css/all.min.css"

// mouting point for the whole app
import App from "@/App.vue"
import './index.css'

// routes
const routes = [
  {
    name: 'landing',
    path: "/",
    component: Landing,
  },
  {
    name: 'spaces',
    path: "/parking-spaces",
    component: () => import('@/views/Spaces.vue'),
  },
  // {
  //   name: 'spaceDetail',
  //   path: "/parking-spaces/:id",
  //   component: () => import('@/views/SpaceDetail.vue'),
  // },
  {
    name: 'searchSubscriptionByArea',
    path: "/parking-spaces/:id/subscription",
    component: () => import('@/views/SearchSubscription.vue'),
    meta: {
      noBreadcrumbs: true
    }
  },
  {
    name: 'newSubscription',
    path: "/parking-spaces/:id/subscription/new",
    component: () => import('@/views/NewSubscription.vue'),
    meta: {
      noBreadcrumbs: true
    }
  },
  {
    name: 'searchSubscription',
    path: "/subscription",
    component: () => import('@/views/SearchSubscription.vue'),
    meta: {
      noBreadcrumbs: true
    }
  },
  {
    name: 'subscriptionDetail',
    path: "/s/:id",
    component: () => import('@/views/SubscriptionDetail.vue'),
    meta: {
      noBreadcrumbs: true
    }
  },
  {
    name: 'searchParkingByArea',
    path: "/parking-spaces/:id/parking",
    component: () => import('@/views/SearchParking.vue'),
    meta: {
      noBreadcrumbs: true
    }
  },
  {
    name: 'searchParking',
    path: "/parking",
    component: () => import('@/views/SearchParking.vue'),
    meta: {
      noBreadcrumbs: true
    }
  },
  {
    name: 'parkingDetail',
    path: "/p/:id",
    component: () => import('@/views/ParkingDetail.vue'),
    meta: {
      noBreadcrumbs: true
    }
  },
  {
    name: 'termsOfUse',
    path: "/terms-of-use",
    component: () => import('@/views/TermsOfUse.vue'),
  },
  {
    name: 'privacyPolicy',
    path: "/privacy-policy",
    component: () => import('@/views/PrivacyPolicy.vue'),
  },
  {
    name: 'cookiePolicy',
    path: "/cookie-policy",
    component: () => import('@/views/CookiePolicy.vue'),
  },
  {
    name: 'returnPolicy',
    path: "/return-policy",
    component: () => import('@/views/ReturnPolicy.vue'),
  },
  {
    name: 'refundPolicy',
    path: "/refund-policy",
    component: () => import('@/views/RefundPolicy.vue'),
  },
  {
    name: 'shippingPolicy',
    path: "/shipping-policy",
    component: () => import('@/views/ShippingPolicy.vue'),
  },
  {
    name: 'aboutUs',
    path: "/about-us",
    component: () => import('@/views/AboutUs.vue'),
  },
  {
    name: 'team',
    path: "/our-team",
    component: () => import('@/views/OurTeam.vue'),
  },
  {
    name: 'businessSolutions',
    path: "/business-solutions",
    component: () => import('@/views/BusinessSolutions.vue'),
  },
  {
    name: 'contactUs',
    path: "/contact-us",
    component: () => import('@/views/ContactUs.vue'),
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  },
})

const app = createApp(App)

app.use(router)

app.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_ANALYTICS_KEY
  }
}, router)

app.mount("#app")
