export default {
  data () {
    return {
      cities: []
    }
  },
  methods: {
    loadAvailableCities() {
      const cities = JSON.parse(window.localStorage.getItem('cities') || '{}');
      if (!Object.keys(cities).includes('expiry') || cities.expiry <= new Date().getTime()) {
        this.cities = []
        fetch(`${process.env.VUE_APP_API_ROOT}list-cities`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "no-cors"
          })
          .then(response => response.json())
          .then(({ success, data }) => {
            if (success) {
              const result = {
                data,
                expiry: new Date().getTime() + (7 * 24 * 60 * 60 * 1000) // 7 days
              }
              window.localStorage.setItem('cities', JSON.stringify(result));
              this.cities = data
            }
          });
        
      } else {
        this.cities = cities.data
      }
    }
  }
}
