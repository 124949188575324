export default {
    data () {
        return {
            vehicleTypes: []
        }
    },
    created () {
        this.loadVehicleTypes()
    },
    methods: {
        loadVehicleTypes () {
            const key = 'vehicletypes';
            const vehicleTypes = JSON.parse(window.localStorage.getItem(key) || '{}');
            if (!Object.keys(vehicleTypes).includes('expiry') || vehicleTypes.expiry <= new Date().getTime()) {
                fetch(`${process.env.VUE_APP_API_ROOT}vehicletype`, {
                        method: "POST", // *GET, POST, PUT, DELETE, etc.
                        mode: "no-cors",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then(response => response.json())
                    .then(({ success, data }) => {
                        if (success) {
                            const result = {
                            data,
                            expiry: new Date().getTime() + (14 * 24 * 60 * 60 * 1000) // 14 days
                            }
                            window.localStorage.setItem(key, JSON.stringify(result));
                            this.vehicleTypes = data
                        } else {
                            this.vehicleTypes = []
                        }
                    })
            } else {
                this.vehicleTypes = vehicleTypes.data
            }
        }
    }
}
